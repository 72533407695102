import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Today is our free New Years Eve workout at 10:00am at The Ville! All
other classes at both locations are cancelled. Louisville East will have
open gym from 9am-6pm. No open gym at The Ville today.`}</strong></p>
    <p>{`1 Lap Run Buy In`}</p>
    <p><em parentName="p">{`then, 2 rounds of: `}</em></p>
    <p>{`20-Goblet Squats (53/35)`}</p>
    <p>{`17-Burpees`}</p>
    <p>{`20-Ring Dips`}</p>
    <p>{`17-V Ups`}</p>
    <p>{`20-Box Jumps (24/20)`}</p>
    <p>{`17-Burpees`}</p>
    <p>{`20-Wall Balls (20/14)`}</p>
    <p>{`17-V Ups`}</p>
    <p>{`1 Lap Run Cash Out.`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will be closed on New Years Day, Sunday. We will reopen
Monday for all regularly scheduled classes at both locations!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 2nd
so get ready! RX Men, RX Women, scaled, and masters (50+) divisions. If
you would like to register please email Daniel at:
Daniel\\@crossfitlouisvilleeast.com with which division you’d like to
enter. Each workout will a daily WOD and you’ll be able to do it during
class so there’s no reason not to enter! Each workout will also be done
only once so unlike the Open it won’t interfere with our regular
programming.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar will Dawn from Sports Nutrition 2 Go in Cincinnati.
Metabolic Testing will be offered before and after the seminar as
well!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      